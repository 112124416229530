<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <section>
      <img v-if="type == 'it'" class="smart-farm-bg" src="../../assets/images/join/it.png" alt="">
      <img v-if="type == 'hardware'" class="smart-farm-bg" src="../../assets/images/join/hardware.png" alt="">
      <img v-if="type == 'mart'" class="smart-farm-bg" src="../../assets/images/join/mart.png" alt="">
      <img v-if="type == 'code'" class="smart-farm-bg" src="../../assets/images/join/code.png" alt="">
      <img v-if="type == 'sxj'" class="smart-farm-bg" src="../../assets/images/join/sxj.png" alt="">
    </section>
    <section class="smart-farm-container">
      <!-- 研发部 -->
      <Development v-if="type == 'it'"></Development>
      <!-- 硬件 -->
      <Hardware v-if="type == 'hardware'"></Hardware>
       <!-- 市场 -->
       <Mart v-if="type == 'mart'"></Mart>
        <!-- 教育 -->
      <Code v-if="type == 'code'"></Code>
       <!-- 社享家 -->
       <Sxj v-if="type == 'sxj'"></Sxj>
    </section>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
import Development from './components/development.vue'
import Hardware from './components/hardware.vue'
import Mart from './components/mart.vue'
import Code from './components/code.vue'
import Sxj from './components/sxj.vue'
export default {
  data() {
    return {
      type:''
    }
  },
  components: {
    Nav,
    Footer,
    Development,
    Hardware,
    Mart,
    Code,
    Sxj
  },
  methods: {

  },
  created(){
    this.type = this.$route.query.type
  },
  beforeDestroy() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm {
  width: 100vw;
  padding-top: 80px;
}

.smart-farm-bg {
  width: 100%;
  height: 100%;
}

.smart-farm-container {
  width: 56%;
  max-width: 1075px;
  margin: 84px auto 152px;
  font-size: 18px;
  color: #404040;
  text-align: left;
}
</style>
<template>
  <div class="development">
    <div class="container-list">
        <h3>嵌入式工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、研发嵌入式设备；</li>
          <li>2、开发应用程序、驱动程序；</li>
          <li>3、对接云平台，实现多种协议的数据通信；</li>
          <li>4、设计通信协议，实现上下位机即传感器之间的高效稳定通信；</li>
          <li>5、编写产品、方案文档。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、本科及以上学历，电子信息、自动化、计算机、通信相关专业；</li>
          <li>2、掌握C/C++语言，Linux、Qt等嵌入式开发环境；</li>
          <li>3、掌握硬件设计，AltiumDesigner原理图、PCB绘制；</li>
          <li>4、熟悉STM32、ESP、BLE等芯片开发；</li>
          <li>5、熟悉FreeRTOS、RTT、uCos嵌入式操作系统；</li>
          <li>6、熟悉I2C、SPI、485、Lora、BLE、TCP/IP、Modbus等通信接口或协议；</li>
          <li>7、了解Python、CV者优先考虑。</li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .container-list {

h3 {
  font-size: 43px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 59px;
}

h4 {
  font-size: 36px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 46px;
}

li {
  line-height: 1.8;
}

.container-line {
  width: 100%;
  display: block;
  margin-top: 50px;
}

.container-list-ask {
  margin-top: 61.5px;
  margin-bottom: 36px;
}
}
</style>
<template>
  <div class="development">
    <div class="container-list">
        <h3>JAVA开发工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、负责公司产品的功能迭代开发和维护</li>
          <li>2、负责与外面合作方以及省电信能力接口对接和调试</li>
          <li>3、负责公司产品技术架构演进 (可用性、扩展性提升) 以及持续集成中台化落地相关开发工作</li>
          <li>4、根据工作安排高效、高质地完成代码编写，确保符合规范的后端端代码规范</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1.具有扎实的java基础，具有面向对象编程的思想</li>
          <li>2.熟练使用Spring，SpringMVC，Mybatis.Springboot等框架</li>
          <li>3.熟悉Dubbo，SpringCloud等微服务框架</li>
          <li>4.熟练掌握Maven，Git等工具的使用</li>
          <li>5.熟练应用主流数据库软件，能进行复杂SQL语句的编写</li>
          <li>6.能承受压力，具备良好的沟通协调能力，工作积极主动认真负责，富有团队精神，具有良好的自学能力和独立解决问题的能力</li>
        </ul>
      </div>
      <div class="container-list container-list-festyle">
        <h3>前端开发工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1.负责公司所有产品线的前端框架/核心模块设计和开发工作，对代码质量及进度负责</li>
          <li>2.与设计师、产品工程师密切配合，实现前端UI和交互方面的开发和迭代</li>
          <li>3.持续的优化前端体验和页面响应速度，并保证兼容性和执行效率</li>
          <li>4.负责前端重点技术方案选型，架构制定，攻克技术难关，保证前端交付质量</li>
          <li>5.研究和探索前沿前端技术，持续提升前端技术先进性及研发效率</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1.精通HTML、Css3、熟悉DIV+CSS、熟悉Echart、axios等技术、熟悉webpack脚本配置</li>
          <li>2.根据需求能快速设计、开发出UI原型、具备复杂AJax交互开发,对常用的基础组件能够组件化、模块化封装、形成可快速复制的组件</li>
          <li>3.熟悉SVN、GIT等主流项目代码管理工具</li>
          <li>4.熟悉VUE开发框架，并能结合vue生态进行页面开发</li>
          <li>5.具有产品前端设计开发经验者优先</li>
          <li>6.具备良好的自我学习能力、理解沟通能力和团队合作精神、责任心里、能承受一定的工作压力</li>
        </ul>
      </div>
      <div class="container-list container-list-pmstyle">
        <h3>产品经理</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1.负责进行公司产品的设计、跟进、验收和产品上线后的迭代优化</li>
          <li>2.负责产品的需求分析，产品原型设计，以及相关产品文档撰写</li>
          <li>3.协调产品各个阶段所需资源，与合作团队沟通，推动产品设计、开发、测试、上线，确保产品性能和体验</li>
          <li>4.负责政务大数据趋势研究与竞争对手的产品分析，配合售前、市场等相关人员进行沟通交流</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1.负责公司项目需求调研: 负责公司 (软硬件结合) 产品定义，规划，设计和执行，负责产品的生命周期管理</li>
          <li>2.能够分析项目需求，产品需求，分析可能的竞品和市场动态，规划产品路线图，提出产品需求或改进意见</li>
          <li>3. 3年以上产品经理经验，大专及以上学历</li>
          <li>4.能够设计新产品，功能，外观的概念和原型，协调市场，开发，运营。管理等团队确立产品方案</li>
          <li>5.能够跟踪产品开发进度，完成产品的开发、验证等相关工作</li>
          <li>6.具备较强的文档能力</li>
          <li>7.有水利，农业，林业相关项目经验者优先考虑</li>
        </ul>
      </div>
      <div class="container-list container-list-teststyle">
        <h3>测试工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1.负责项目全流程测试需求分析、测试案例编写、测试执行并使用工具进行管理、跟踪缺陷</li>
          <li>2.对接行方业务人员及开发人员，沟通、跟踪需求及测试过程中的问题</li>
          <li>3.配合组长及项目经理完成日常工作</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1.大专以上学历，1年以上软件测试工作经验</li>
          <li>2.熟练使用常用的测试方法，能够独立完成测试用例、测试需求分析的编写</li>
          <li>3.熟练使用常用的BUG管理工具: 禅道、QC等</li>
        </ul>
      </div>
      <div class="container-list container-list-networkstyle">
        <h3>网络工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1.负责对公司机房网络设备、服务器、UPS等设备进行日常维护，确保系统服务连续正常运行，无重大事故</li>
          <li>2.负责处理网络故障，确保网络的通畅及网络信息安全</li>
          <li>3.为终端用户提供必要的技术支持，指导和监督终端用户正确使用公司的计算机及网络系统</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1.具备三年以上专职网络工程师从业经验及政府行业网络项目实施经验</li>
          <li>2.拥有HUAWEI、H3C、CISCO等设备厂商NP级别认证证书优先</li>
          <li>3.熟悉主流设备厂商的数通产品及安全产品的功能、作用及参数指标</li>
          <li>4.负责与客户进行技术交流及研讨，根据用户需求进行项目分析及可行性分析，编制整体设计方案;项目现场进行安装调试及后期维护与培训</li>
          <li>5.通过数据分析和其他相关方式，分析处理网络故障:编制实施技术文档及售后服务档案</li>
          <li>6.了解网络基础架构、设计布线方案</li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .container-list {

h3 {
  font-size: 43px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 59px;
}

h4 {
  font-size: 36px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 46px;
}

li {
  line-height: 1.8;
}

.container-line {
  width: 100%;
  display: block;
  margin-top: 50px;
}

.container-list-ask {
  margin-top: 61.5px;
  margin-bottom: 36px;
}
}

.container-list-festyle {
margin-top: 119px;
}

.container-list-pmstyle {
margin-top: 148px;
}
.container-list-teststyle {
margin-top: 165px;
}
.container-list-networkstyle {
margin-top: 203px;
}

</style>
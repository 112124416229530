<template>
  <div class="development">
    <div class="container-list">
        <h3>课程顾问</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、依托公司提供资源，运营多种营销模式（电话、网络、校园宣讲会等）与学员进行沟通；</li>
          <li>2、根据意向学员需求，推荐不同培训课程；</li>
          <li>3、顾问式营销，上手快，难度低，有无经验均可。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、20-35周岁，耐心细致；</li>
          <li>2、大专以上学历，热爱教育事业；</li>
          <li>3、具备良好的沟通能力，学习能力。</li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .container-list {

h3 {
  font-size: 43px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 59px;
}

h4 {
  font-size: 36px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 46px;
}

li {
  line-height: 1.8;
}

.container-line {
  width: 100%;
  display: block;
  margin-top: 50px;
}

.container-list-ask {
  margin-top: 61.5px;
  margin-bottom: 36px;
}
}

</style>
<template>
  <div class="development">
    <div class="container-list">
        <h3>抖音商务BD</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、根据公司需求进行招商，商业伙伴的拓展.招募.洽谈并达成商务合作;</li>
          <li>2、维护公司与合作伙伴之间的长期商务合作关系，推进进展;</li>
          <li>3、负责制定合作伙伴相关政策，并推动制定的落地.执行和完善;</li>
          <li>4、配合运营完成所需产品的内容整理提炼，了解熟悉流程.操作;</li>
          <li>5、根据需求招商，对接商家品牌并建立产品数据库。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、1年以上市场BD工作经验；</li>
          <li>2、善于沟通，具备良好的应变能力；</li>
          <li>3、熟练使用办公软件。</li>
        </ul>
      </div>
      <div class="container-list container-list-festyle">
        <h3>短视频摄影师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、进行短视频拍摄工作；</li>
          <li>2、协助剪辑完成最终短视频制作。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、1年以上同岗位经验；</li>
          <li>2、具备良好的沟通协调能力。</li>
        </ul>
      </div>
      <div class="container-list container-list-pmstyle">
        <h3>短视频编导</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、负责抖音短视频选题规划、脚本的编辑撰写；</li>
          <li>2、对接后期，提出剪辑意见；</li>
          <li>3、新媒体文案编辑。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、1年以上同岗位经验；</li>
          <li>2、具备良好的沟通协调能力；</li>
          <li>3、具有良好的创造力和文字功底。</li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .container-list {

h3 {
  font-size: 43px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 59px;
}

h4 {
  font-size: 36px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 46px;
}

li {
  line-height: 1.8;
}

.container-line {
  width: 100%;
  display: block;
  margin-top: 50px;
}

.container-list-ask {
  margin-top: 61.5px;
  margin-bottom: 36px;
}
}

.container-list-festyle {
margin-top: 119px;
}

.container-list-pmstyle {
margin-top: 148px;
}
.container-list-teststyle {
margin-top: 165px;
}
.container-list-networkstyle {
margin-top: 203px;
}

</style>
<template>
  <div class="development">
    <div class="container-list">
        <h3>解决方案工程师</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、负责公司核心产品及项目的售前支持与推广，支持云服务、大数据、智慧城市、政府信息化等方案的技术架构方案编写；</li>
          <li>2、负责给销售、项目团队提供技术方案保障，面向客户提供整体信息化顶层设计和咨询规划、解决方案设计；</li>
          <li>3、熟悉国内工程类、服务类项目的常规运作流程，熟练掌握招投标相关知识，协助项目的招投标落地；</li>
          <li>4、负责面向区域政府及各细分领域热点洞察。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、全日制一本大学本科及以上学历，电子工程、机电、物理、计算机软件等相关专业；</li>
          <li>2、熟练使用office、AutoCAD、Visio、Xmind等工具软件</li>
          <li>3、具备良好的学习能力，在公司的培养及工作中不断加强自身专业能力；</li>
          <li>4、拥有良好的沟通表达及理解能力，能够准确理解把握客户需求；执行力强，且有较强的责任心和团队合作精神。</li>
        </ul>
      </div>
      <div class="container-list container-list-festyle">
        <h3>项目经理</h3>
        <h4>岗位职责</h4>
        <ul>
          <li>1、负责现场直面用户沟通，汇报等工作；</li>
          <li>2、负责现场与三方厂家公司沟通，协调等工作；</li>
          <li>3、负责与现场及远程运营小伙伴沟通落实项目进度以及项目质量等工作；</li>
          <li>4、负责内部协调资源沟通以及内部信息同步等工作，起承上启下作用。</li>
        </ul>
        <img class="container-line" src="../../../assets/images/join/line.png" alt="">
        <h4 class="container-list-ask">任职要求</h4>
        <ul>
          <li>1、项目管理（对内、对外、对三方）;</li>
          <li>2、汇报演示；</li>
          <li>3、能够接受出差；</li>
          <li>4、项目进度把握。</li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .container-list {

h3 {
  font-size: 43px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 59px;
}

h4 {
  font-size: 36px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 46px;
}

li {
  line-height: 1.8;
}

.container-line {
  width: 100%;
  display: block;
  margin-top: 50px;
}

.container-list-ask {
  margin-top: 61.5px;
  margin-bottom: 36px;
}
}

.container-list-festyle {
margin-top: 119px;
}

.container-list-pmstyle {
margin-top: 148px;
}
.container-list-teststyle {
margin-top: 165px;
}
.container-list-networkstyle {
margin-top: 203px;
}

</style>